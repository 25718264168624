exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cenovnik-jsx": () => import("./../../../src/pages/cenovnik.jsx" /* webpackChunkName: "component---src-pages-cenovnik-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-i-kako-do-nas-jsx": () => import("./../../../src/pages/kontakt-i-kako-do-nas.jsx" /* webpackChunkName: "component---src-pages-kontakt-i-kako-do-nas-jsx" */),
  "component---src-pages-o-beauty-salonu-jsx": () => import("./../../../src/pages/o-beauty-salonu.jsx" /* webpackChunkName: "component---src-pages-o-beauty-salonu-jsx" */),
  "component---src-pages-poklon-vauceri-jsx": () => import("./../../../src/pages/poklon-vauceri.jsx" /* webpackChunkName: "component---src-pages-poklon-vauceri-jsx" */),
  "component---src-pages-popusti-i-akcije-jsx": () => import("./../../../src/pages/popusti-i-akcije.jsx" /* webpackChunkName: "component---src-pages-popusti-i-akcije-jsx" */),
  "component---src-pages-tretmani-depilacija-jsx": () => import("./../../../src/pages/tretmani/depilacija.jsx" /* webpackChunkName: "component---src-pages-tretmani-depilacija-jsx" */),
  "component---src-pages-tretmani-jsx": () => import("./../../../src/pages/tretmani.jsx" /* webpackChunkName: "component---src-pages-tretmani-jsx" */),
  "component---src-pages-tretmani-laserska-epilacija-jsx": () => import("./../../../src/pages/tretmani/laserska-epilacija.jsx" /* webpackChunkName: "component---src-pages-tretmani-laserska-epilacija-jsx" */),
  "component---src-pages-tretmani-lux-spa-tretman-jsx": () => import("./../../../src/pages/tretmani/lux-spa-tretman.jsx" /* webpackChunkName: "component---src-pages-tretmani-lux-spa-tretman-jsx" */),
  "component---src-pages-tretmani-manikir-jsx": () => import("./../../../src/pages/tretmani/manikir.jsx" /* webpackChunkName: "component---src-pages-tretmani-manikir-jsx" */),
  "component---src-pages-tretmani-masaza-jsx": () => import("./../../../src/pages/tretmani/masaza.jsx" /* webpackChunkName: "component---src-pages-tretmani-masaza-jsx" */),
  "component---src-pages-tretmani-pedikir-jsx": () => import("./../../../src/pages/tretmani/pedikir.jsx" /* webpackChunkName: "component---src-pages-tretmani-pedikir-jsx" */),
  "component---src-pages-tretmani-profesionalna-sminka-jsx": () => import("./../../../src/pages/tretmani/profesionalna-sminka.jsx" /* webpackChunkName: "component---src-pages-tretmani-profesionalna-sminka-jsx" */),
  "component---src-pages-tretmani-tretmani-i-nega-lica-jsx": () => import("./../../../src/pages/tretmani/tretmani-i-nega-lica.jsx" /* webpackChunkName: "component---src-pages-tretmani-tretmani-i-nega-lica-jsx" */)
}

